var prodcat = prodcat || {};

prodcat.data = prodcat.data || {};

(function ($) {
  $(document).on('product.skuSelect', '.js-product', function (event, skuBaseId) {
    var $product = $(this);
    // var productId = $product.data('product-id');
    var skuData = {};
    var $skuImages = $();

    skuData = prodcat.data.getSku(skuBaseId);

    // Swap shade names for shaded products
    if (!!skuData && skuData.hasOwnProperty('SHADENAME')) {
      $('.js-shadename', $('.product-brief__subtitle', $product)).html(skuData.SHADENAME);
    }

    // Swap images when a new sku is selected
    if (!!skuData && skuData.hasOwnProperty('MEDIUM_IMAGE_V2') && skuData.MEDIUM_IMAGE_V2.length > 0) {
      $skuImages = $('.js-spp-link img', $product);

      if (!$skuImages.length) {
        return;
      }

      for (var i = 0, j = $skuImages.length; i <= j; i++) {
        if ($skuImages.eq(i) && skuData.MEDIUM_IMAGE_V2[i]) {
          $skuImages.eq(i).attr('src', skuData.MEDIUM_IMAGE_V2[i]);
        }
      }
    }
  });
})(jQuery);
